import React from 'react';
import { bool, object, string } from 'prop-types';
import cx from 'classnames';
import { t } from '@jotforminc/translation';

import { BadgeHipaa, BadgeGovernment } from '../Badges';
import { logoSideLinkPropsDefaultProps, logoSideLinkPropsPropTypes, themePropType } from '../../propTypes';

const SideLink = ({
  user,
  theme,
  isMobileMenuActive,
  logoSideLinkProps,
  currentPath,
  isEnterprise
}) => {
  const {
    sideLinkText, dividerColor, sideLinkUrl, sideLinkClassName,
    sideLinkTextColor, sideLinkLogoAsHtmlString
  } = logoSideLinkProps;

  const linkClasses = cx('locale jfRHeader--logo-text', sideLinkClassName);
  const sideLinkClasses = cx('jfRHeader--logo-custom', sideLinkClassName);

  const { isGovernment: showGovernmentBadge, isHIPAA } = user || {};
  const showHipaaBadge = isHIPAA === '1';

  const isBadgeAvailable = () => {
    const pathname = currentPath?.replace(/\/platform\/(.*?)\//, '/')?.replace(/\/$/, '');
    const isOnPage = ['/pricing', '/myaccount', '/myforms', '/mytables', '/myreports', '/myapprovals', '/myapps', '/mysigndocuments', '/f/headerapp'].some(page => pathname?.includes(page));
    return isOnPage && !sideLinkText && !sideLinkLogoAsHtmlString;
  };

  return (
    <>
      {sideLinkText && (
        <>
          <div className="jfRHeader--logo-divider" style={{ backgroundColor: dividerColor }} />
          <a
            href={sideLinkUrl}
            className={linkClasses}
            style={{ color: sideLinkTextColor }}
            tabIndex={0}
          >
            {t(sideLinkText)}
          </a>
        </>
      )}
      {sideLinkLogoAsHtmlString && (
        <>
          <div className="jfRHeader--logo-divider" style={{ backgroundColor: dividerColor }} />
          <a
            href={sideLinkUrl}
            className={sideLinkClasses}
            aria-label="Jotform Logo"
            dangerouslySetInnerHTML={{ __html: sideLinkLogoAsHtmlString }}
            tabIndex={0}
          />
        </>
      )}
      {/* hipaa badge */}
      {isBadgeAvailable() && showHipaaBadge && (
        <BadgeHipaa
          theme={theme}
          isMobileMenuActive={isMobileMenuActive}
        />
      )}
      {/* government badge */}
      {isBadgeAvailable() && !showHipaaBadge && isEnterprise && showGovernmentBadge && (
        <BadgeGovernment
          theme={theme}
          isMobileMenuActive={isMobileMenuActive}
        />
      )}
    </>
  );
};

SideLink.propTypes = {
  user: object.isRequired,
  theme: themePropType.isRequired,
  isMobileMenuActive: bool,
  logoSideLinkProps: logoSideLinkPropsPropTypes,
  currentPath: string.isRequired,
  isEnterprise: bool.isRequired
};

SideLink.defaultProps = {
  isMobileMenuActive: false,
  logoSideLinkProps: logoSideLinkPropsDefaultProps
};

export default SideLink;
