import React from 'react';
import cx from 'classnames';
import { bool } from 'prop-types';
import { t } from '@jotforminc/translation';
import { TEXTS } from '../../constants';
import GovernmentSvg from '../../assets/svg/government.svg';
import { isDarkTheme } from '../../utils';
import { themePropType } from '../../propTypes';

const BadgeGovernment = ({ theme, isMobileMenuActive }) => {
  return (
    <div
      className={cx('jfRHeader--hipaa', {
        'isDarkTheme isNotMobileMenuActive': isDarkTheme(theme) && !isMobileMenuActive,
        'isNotDarkTheme isMobileMenuActive': !isDarkTheme(theme) || isMobileMenuActive
      })}
    >
      <GovernmentSvg className="jfRHeader--hipaa-logo" />
      <span className={cx('jfRHeader--hipaa-text locale', {
        isDark: isDarkTheme(theme) && !isMobileMenuActive,
        isLight: !isDarkTheme(theme) || isMobileMenuActive
      })}
      >
        {t(TEXTS.GOVERNMENT)}
      </span>
    </div>
  );
};

BadgeGovernment.propTypes = {
  theme: themePropType.isRequired,
  isMobileMenuActive: bool
};

BadgeGovernment.defaultProps = {
  isMobileMenuActive: false
};

export default BadgeGovernment;
